import axios from "axios"
import { useCallback, useState } from "react"
import { editarContext } from "../../../context/Editar";
import { useContext } from "react";
import Select from 'react-select'
import { vencimientoContext } from "../../../context/Vencimiento";
import { useCookies } from 'react-cookie';
export default function SeccionCobroEditar({ expediente }) {
    const [cobroData, setCobroData] = useState({
        capital: expediente.capital,
        mesCobrado: expediente.mesCobrado,
        mesEstimado: expediente.mesEstimado,
        modoDePago: expediente.modoDePago,
        honorariosCobro: expediente.honorariosCobro,
        estadoCobro: expediente.estadoCobro,
        nroFactura: expediente.nroFactura,
        facturadoPor: expediente.facturadoPor,
        honorariosCliente: expediente.hnriosCliente,
        honorariosFacturado: expediente.honorariosFacturado,
    })
    const [cookies] = useCookies(['user']);
    const [carga, setCarga] = useState(false)
    const hoy = new Date().toISOString().split('T')[0];
    const { vencimiento } = useContext(vencimientoContext)
    const cobroDataUpdate = useCallback(async (e) => {
        e.preventDefault()
        await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/cobro/update`,
            {
                id: expediente.id,
                capital: cobroData.capital,
                mesCobrado: cobroData.mesCobrado,
                mesEstimado: cobroData.mesEstimado,
                modoDePago: cobroData.modoDePago,
                honorariosCobro: cobroData.honorariosCobro,
                estadoCobro: cobroData.estadoCobro,
                nroFactura: cobroData.nroFactura,
                facturadoPor: cobroData.facturadoPor,
                honorariosCliente: cobroData.honorariosCliente,
                honorariosFacturado: cobroData.honorariosFacturado,
                compReq: expediente.companiasTablaID,
                fechaCambioEstado: expediente.fechaCambioEstado,
                mesCobradoViejo: expediente.mesCobrado,
                vencimiento: new Date(vencimiento).toLocaleDateString('fr-FR'),
                modificadoPor: cookies.user.nombre,
                hoy: hoy,
                esNovedades: expediente.asignadoA !== cookies.user.nombre ? 1 : 0,
                iconoMediacion: expediente.iconoMediacion,
                estadoDelExp: expediente.estado
            }).then(async (response) => {
                setCarga(true)
                if (response) {
                    if (cobroData.estadoCobro === "COBRADO") {
                        axios.post(`https://api.tuveunchoque.com.ar/api/mail/cobrado`, {
                            nro: expediente.nro,
                            pas: expediente.productorAsociado,
                            honorariosPas: expediente.honorarios,
                            patente: expediente.dominioCliente
                        }
                        )
                    } else {
                        setTimeout(async () => {
                            window.location.reload()
                        }, 1000);
                    }
                }
            })
    }, [cobroData, vencimiento, expediente])
    const pagoInput = ['CHEQUE', 'CLIENTE', 'TRANSFERENCIA']
    const estadoCobroInput = ['PENDIENTE', 'COBRADO']
    const facturadoInput = ['JUAN', 'FRANCO', 'MAURO', 'CLIENTE', 'SANDRA', 'FERLAUTO', 'FACUNDO']
    const { setEditarFalse } = useContext(editarContext)
    function isOnlyNumbers(input) {
        if (input === '' || input === null || input === undefined) {
            return true;
        }
        const regex = /^[0-9]+$/;
        return regex.test(input);
    }
    function isValid(value) {
        return value !== null && value !== undefined && value !== '' && value !== false && value !== 0;
    }
    const checkFields = () => {
        const missingFields = [];

        // Lista de campos a verificar
        const fields = [
            { name: 'Capital', value: cobroData.capital },
            { name: 'Mes Estimado', value: cobroData.mesEstimado },
            { name: 'Honorarios Facturado', value: cobroData.honorariosFacturado },
            { name: 'Número de Factura', value: cobroData.nroFactura },
            { name: 'Facturado Por', value: cobroData.facturadoPor },
            { name: 'Estado de Cobro', value: cobroData.estadoCobro },
            { name: 'Honorarios Cliente', value: cobroData.honorariosCliente }
        ];
        fields.forEach(field => {
            if (!isValid(field.value)) {
                missingFields.push(field.name);
            }
        });

        return missingFields;
    };
    const missingFields = checkFields();
    return (
        <div>
            <div className="pe-3 ps-3">
                <form className="mt-1" onSubmit={cobroDataUpdate}>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Ofrecimiento (*)</label>
                            <input type="number" class="form-control" value={cobroData.capital}
                                required
                                onChange={(e) =>
                                    setCobroData((prevState) => ({
                                        ...prevState,
                                        capital: e.target.value,
                                    }))} />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Mes Cobrado</label>
                            <input type="month" class="form-control" value={cobroData.mesCobrado} required={cobroData.estadoCobro === 'COBRADO' ? true : false}
                                onChange={(e) =>
                                    setCobroData((prevState) => ({
                                        ...prevState,
                                        mesCobrado: e.target.value,
                                    }))} />
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Mes Estimado (*)</label>
                            <input type="month" class="form-control" value={cobroData.mesEstimado}
                                required
                                onChange={(e) =>
                                    setCobroData((prevState) => ({
                                        ...prevState,
                                        mesEstimado: e.target.value,
                                    }))} />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Modo de pago (*)</label>
                            <Select
                                options={pagoInput.sort((a, b) => a.localeCompare(b)).map(pago => ({ value: pago, label: pago }))}
                                placeholder={expediente.modoDePago}
                                onChange={(newValue) => setCobroData((prevState) => ({
                                    ...prevState,
                                    modoDePago: newValue.value,
                                }))}
                            />
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Honorarios Facturado</label>
                            <input type="number" class="form-control" value={cobroData.honorariosFacturado}
                                onChange={(e) =>
                                    setCobroData((prevState) => ({
                                        ...prevState,
                                        honorariosFacturado: e.target.value,
                                    }))} />
                        </div>
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Honorarios Cliente</label>
                            <input type="number" class="form-control" value={cobroData.honorariosCliente}
                                onChange={(e) =>
                                    setCobroData((prevState) => ({
                                        ...prevState,
                                        honorariosCliente: e.target.value,
                                    }))} />
                        </div>
                        {cobroData.facturadoPor === "JUAN" ?
                            <div class="col">
                                <label class="mb-1 letraPlanillaExpediente">Honorarios Cobrados Cia</label>
                                <input type="number" class="form-control" value={cobroData.honorariosCobro} required={cobroData.estadoCobro === 'COBRADO' ? true : false}
                                    onChange={(e) =>
                                        setCobroData((prevState) => ({
                                            ...prevState,
                                            honorariosCobro: e.target.value,
                                        }))} />
                            </div>
                            :
                            <div class="col">
                                <label class="mb-1 letraPlanillaExpediente">Honorarios Cobrados Cia</label>
                                <input type="number" class="form-control" value={cobroData.honorariosCobro}
                                    onChange={(e) =>
                                        setCobroData((prevState) => ({
                                            ...prevState,
                                            honorariosCobro: e.target.value,
                                        }))} />
                            </div>}
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Estado Cobro (*)</label>
                            <Select
                                placeholder={expediente.estadoCobro}
                                required
                                options={estadoCobroInput.sort((a, b) => a.localeCompare(b)).map(estadoCobro => ({ value: estadoCobro, label: estadoCobro }))}
                                onChange={(newValue) => setCobroData((prevState) => ({
                                    ...prevState,
                                    estadoCobro: newValue.value,
                                }))}
                            />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Nro Factura (*)</label>
                            <input type="text" class="form-control" value={cobroData.nroFactura}
                                required
                                onChange={(e) =>
                                    setCobroData((prevState) => ({
                                        ...prevState,
                                        nroFactura: e.target.value,
                                    }))} />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Facturado Por (*)</label>
                            <Select
                                required
                                options={facturadoInput.sort((a, b) => a.localeCompare(b)).map(f => ({ value: f, label: f }))}
                                placeholder={expediente.facturadoPor}
                                onChange={(newValue) => setCobroData((prevState) => ({
                                    ...prevState,
                                    facturadoPor: newValue.value,
                                }))}
                            />
                        </div>
                    </div>
                    {cobroData.estadoCobro === "COBRADO" ?
                        <div className="d-flex justify-content-center mt-4 mb-3">
                            <button type="submit" className="btn-primary btn" disabled={isOnlyNumbers(cobroData.honorariosCliente) === false ? true : isOnlyNumbers(cobroData.honorariosCobro) === false ? true : isOnlyNumbers(cobroData.honorariosFacturado) === false ? true : isOnlyNumbers(cobroData.capital) === false ? true : false}>Actualizar</button>
                            <button className="btn btn-secondary btn-sm ms-5" onClick={setEditarFalse}>Cancelar</button>
                        </div> :
                        <div className="d-flex justify-content-center mt-4 mb-3">
                            <button type="submit" className="btn-primary btn" disabled={!vencimiento ? true : isOnlyNumbers(cobroData.honorariosCliente) === false ? true : isOnlyNumbers(cobroData.honorariosCobro) === false ? true : isOnlyNumbers(cobroData.honorariosFacturado) === false ? true : isOnlyNumbers(cobroData.capital) === false ? true : false}>Actualizar</button>
                            <button className="btn btn-secondary btn-sm ms-5" onClick={setEditarFalse}>Cancelar</button>
                        </div>}
                    {!vencimiento ? <div className="center"><p className="text-danger">Se necesita actualizar la fecha de vencimiento</p></div> : <div></div>}
                    {isOnlyNumbers(cobroData.capital) === false ? <div className="center"><p className="text-danger">El campo Capital contiene valores diferentes a nros</p></div> : <div></div>}
                    {isOnlyNumbers(cobroData.honorariosFacturado) === false ? <div className="center"><p className="text-danger">El campo Honorarios Facturado contiene valores diferentes a nros</p></div> : <div></div>}
                    {isOnlyNumbers(cobroData.honorariosCliente) === false ? <div className="center"><p className="text-danger">El campo Honorarios Cliente contiene valores diferentes a nros</p></div> : <div></div>}
                    {isOnlyNumbers(cobroData.honorariosCobro) === false ? <div className="center"><p className="text-danger">El campo Honorarios Cobrados Cia contiene valores diferentes a nros</p></div> : <div></div>}
                    {expediente.iconoMediacion === 1 && missingFields.length > 0 ? <div className="center"><p className="text-danger">El expediente paso por mediacion. Los siguientes campos deben ser actualizados para pasar a "EN PROCESO DE PAGO":
                        <ul className="d-flex">
                            {missingFields.map((field, index) => (
                                <li className="me-5" key={index}>{field}</li>
                            ))}
                        </ul></p></div> : <div></div>}
                    {carga ?
                        <div className="center">
                            <div class="spinner-border text-primary center" role="status">
                                <span class="visually-hidden center">Loading...</span>
                            </div>
                        </div> : <div></div>}
                </form>
            </div>
        </div>
    )
}