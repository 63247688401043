import axios from "axios";
import { useState, useEffect } from "react";
import "./novedadesNuevo.css";
import ExpedienteTicket from "../components/Ticket/ExpedienteTicket";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { checkContext } from "../context/Check";
import { useContext } from "react";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import Select from 'react-select'
import './paginate.css'
import Excel from "./Excel";
import iconoFiltrar from "../images/iconoFiltrar.png";
import iconoGlobal from "../images/iconoGlobal.png";
import fresh from "../images/fresh.png";
import NovedadesTicket2025 from "../components/Novedades2025/NovedadesTicket2025";
export default function NovedadesNuevo() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['user'], ['expNovedades']);
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [asignado, setAsignado] = useState([]);
    const [preCarga, setPreCarga] = useState(false)
    useEffect(() => {
        if (cookies.user.nombre === 'FRANCO') {
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/ticketsFranco").then((res) => {
                setExpedientes(res.data.reverse());
                setPreCarga(true)
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tickets").then((res) => {
                setExpedientes(res.data.reverse());
            });
            axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros").then((res) => {
                setProductores(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
                setCompanias(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignado").then((res) => {
                setAsignado(res.data);
            });
        } else {
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tickets").then((res) => {
                setExpedientes(res.data.reverse());
                setPreCarga(true)
            });
            axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros").then((res) => {
                setProductores(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias").then((res) => {
                setCompanias(res.data);
            });
            axios.get("https://api.tuveunchoque.com.ar/api/gerencia/asignado").then((res) => {
                setAsignado(res.data);
            });
        }
    }, []);
    let updatedList = expedientes
    const prioridadInput = [{ label: "ALTA", value: "ALTA" }, { label: "MEDIA", value: "MEDIA" }, { label: "BAJA", value: "BAJA" }]
    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ARMADO PRELIMINAR", value: "ARMADO PRELIMINAR" },
        { label: "RECLAMO PRESENTADO", value: "RECLAMO PRESENTADO" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const nivelInput = [{ label: "0", value: "0" }, { label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }];
    const fechaInput = [{ label: "HOY", value: "HOY" }, { label: "MAÑANA", value: "MAÑANA" }, { label: "VENCIDOS", value: "VENCIDOS" }];
    const asignadoInput = asignado.filter(a => a.cargo === 'GERENCIA' || a.cargo === 'EMPLEADO').map((a) => {
        return { value: a.nombre, label: a.nombre }
    })
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania }
    })
    const proInput = productores.map((p) => {
        return { value: p.nombre, label: p.nombre }
    })
    const [filtroNovedades, setFiltroNovedades] = useCookies(['filtroNovedades']);
    const [info, setInfo] = useState({
        info: filtroNovedades.filtroNovedades.info,
        pas: filtroNovedades.filtroNovedades.pas,
        comp: filtroNovedades.filtroNovedades.comp,
        estado: filtroNovedades.filtroNovedades.estado,
        nivel: filtroNovedades.filtroNovedades.nivel,
        fecha: filtroNovedades.filtroNovedades.fecha,
        asignado: filtroNovedades.filtroNovedades.asignado,
        prioridad: filtroNovedades.filtroNovedades.prioridad,
        expFrom: filtroNovedades.filtroNovedades.expFrom,
        expTo: filtroNovedades.filtroNovedades.expTo
    });
    const vencenHoy = expedientes?.filter(e => e.asignadoA === cookies.user?.nombre && (new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString())).length
    const vencenManiana = expedientes?.filter(e => e.asignadoA === cookies.user?.nombre && (new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString())).length
    const [filtroAsignado, setFiltroAsignado] = useState(false)
    const [filtroComentario, setFiltroComentario] = useState(true)
    const [filtroFresh, setFiltroFresh] = useState(false)
    const conFiltro = () => {
        if (filtroNovedades.filtroNovedades.info) {
            updatedList = updatedList.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(filtroNovedades.filtroNovedades.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(filtroNovedades.filtroNovedades.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(filtroNovedades.filtroNovedades.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(filtroNovedades.filtroNovedades.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(filtroNovedades.filtroNovedades.info) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(filtroNovedades.filtroNovedades.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.productorAsociado).toLowerCase().includes(JSON.stringify(filtroNovedades.filtroNovedades.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(filtroNovedades.filtroNovedades.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.compania).toLowerCase().includes(JSON.stringify(filtroNovedades.filtroNovedades.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.patenteRequerido).toLowerCase().includes(JSON.stringify(filtroNovedades.filtroNovedades.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nroSeguimientoCompaniaRequerido).toLowerCase().includes(JSON.stringify(filtroNovedades.filtroNovedades.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
        if (filtroNovedades.filtroNovedades.prioridad.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return filtroNovedades.filtroNovedades.prioridad.some((ele) => {
                    return ele.value === expediente.prioridad
                })
            })
        }
        if (filtroNovedades.filtroNovedades.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return filtroNovedades.filtroNovedades.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado
                })
            })
        }
        if (filtroNovedades.filtroNovedades.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return filtroNovedades.filtroNovedades.comp.some((ele) => {
                    return ele.value === expediente.compania
                })
            })
        }
        if (filtroNovedades.filtroNovedades.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return filtroNovedades.filtroNovedades.estado.some((ele) => {
                    return ele.value === expediente.estado
                })
            })
        }
        if (filtroNovedades.filtroNovedades.expFrom) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro >= filtroNovedades.filtroNovedades.expFrom
            })
        }
        if (filtroNovedades.filtroNovedades.expTo) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro <= filtroNovedades.filtroNovedades.expTo
            })
        }
        if (filtroNovedades.filtroNovedades.asignado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return filtroNovedades.filtroNovedades.asignado.some((ele) => {
                    return ele.value === expediente.asignadoA
                })
            })
        } else if (filtroNovedades.filtroNovedades.asignado.length === 0 && !filtroNovedades.filtroNovedades.info) {
            updatedList = updatedList.filter(e => e.asignadoA === cookies.user.nombre)
        }
        if (filtroNovedades.filtroNovedades.nivel.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return filtroNovedades.filtroNovedades.nivel.some((ele) => {
                    return ele.value == expediente.nivel
                })
            })
        }
        if (filtroNovedades.filtroNovedades.fecha.some((ele) => { return ele.value === 'HOY' })) { updatedList = updatedList.filter(e => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString()) }
        if (filtroNovedades.filtroNovedades.fecha.some((ele) => { return ele.value === 'MAÑANA' })) { updatedList = updatedList.filter(e => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString()) }
        if (filtroNovedades.filtroNovedades.fecha.some((ele) => { return ele.value === 'VENCIDOS' })) { updatedList = updatedList.filter(e => e.estado !== 'COBRADO' && e.estado !== 'CERRADO' && Number(Date.parse(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate())) <= Number(Date.parse(moment().subtract(1, 'days').toDate()))) }
        if (filtroAsignado === true && filtroComentario === true) {
            updatedList = updatedList.filter(e => e.novAsig === 1 || e.novCom === 1);
        } else {
            if (filtroAsignado === true) {
                updatedList = updatedList.filter(e => e.novAsig === 1);
            }
            if (filtroComentario === true) {
                updatedList = updatedList.filter(e => e.novCom === 1);
            }
        }
        if (info.info === '' && info.pas.length === 0 && info.comp.length === 0 && info.estado.length === 0 && info.nivel.length === 0 && info.fecha.length === 0 && info.asignado.length === 0 && info.prioridad.length === 0 && info.expFrom === null && info.expTo === null) { updatedList = updatedList.filter(e => e.estado !== 'COBRADO' && e.estado !== 'CERRADO'); }
    }
    conFiltro()
    useEffect(() => {
        setCookie('filtroNovedades', JSON.stringify(info), { path: '/' });
    }, [info]);
    const cook = () => {
        if (!cookies.user) {
            navigate('/login')
        } else if (cookies.user.cargo === 'PAS') {
            navigate('/expedientes')
        }
    }
    cook()
    const vaciar = async () => {
        setFiltroNovedades({ info: '', pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], expFrom: 0, expTo: 0 });
        setInfo({ info: '', pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], expFrom: null, expTo: null });
        setCookie('filtroNovedades', JSON.stringify(info), { path: '/' })
    }
    const filtrar = async () => {
        setCookie('filtroNovedades', JSON.stringify(info), { path: '/' })
    }
    let itemsPerPage = 10
    const [itemOffset, setItemOffset] = useState(0);
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    useEffect(() => {
        setItemOffset(0);
        setCurrentPage(0);
    }, [updatedList.filter(e => e.novCom === 1).length]);
    const ordenarInput = [{ label: '', value: 0 }, { label: 'NRO ↑', value: 'NRO ↑' }, { label: 'NRO ↓', value: 'NRO ↓' }, { label: 'PATENTE ↑', value: 'PATENTE ↑' }, { label: 'PATENTE ↓ZA', value: 'PATENTE ↓ZA' }, { label: 'PAS ↑', value: 'PAS ↑' }, { label: 'PAS ↓ZA', value: 'PAS ↓ZA' }, { label: 'COMPAÑIA ↑', value: 'COMPAÑIA ↑' }, { label: 'COMPAÑIA ↓ZA', value: 'COMPAÑIA ↓ZA' }, { label: 'CLIENTE ↑', value: 'CLIENTE ↑' }, { label: 'CLIENTE ↓ZA', value: 'CLIENTE ↓ZA' }, { label: 'STRO ↑', value: 'STRO ↑' }, { label: 'STRO ↓ZA', value: 'STRO ↓ZA' }, { label: 'VTO ↑', value: 'VTO ↑' }, { label: 'VTO ↓ZA', value: 'VTO ↓ZA' }, { label: 'ULT MOD ↑', value: 'ULT MOD ↑' }, { label: 'ULT MOD ↓ZA', value: 'ULT MOD ↓ZA' }]
    const [sortA, setSortA] = useState({ sort: 'ULT MOD ↑' })
    const sort = () => {
        if (sortA.sort === 'NRO ↑') {
            updatedList.sort(function (a, b) {
                return b.nro - a.nro;
            })
        }
        if (sortA.sort === 'NRO ↓') {
            updatedList.sort(function (a, b) {
                return a.nro - b.nro;
            })
        }
        if (sortA.sort === 'PATENTE ↑') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(a.dominioCliente).localeCompare(JSON.stringify(b.dominioCliente)) }
            })
        }
        if (sortA.sort === 'PATENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(b.dominioCliente).localeCompare(JSON.stringify(a.dominioCliente)) }
            })
        }
        if (sortA.sort === 'PAS ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.productorAsociado).toLowerCase()
                const nameB = JSON.stringify(b.productorAsociado).toLowerCase()
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
            })
        }
        if (sortA.sort === 'PAS ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.productorAsociado).toLowerCase()
                const nameB = JSON.stringify(b.productorAsociado).toLowerCase()
                if (nameB < nameA)
                    return -1;
                if (nameB > nameA)
                    return 1;
            })
        }
        if (sortA.sort === 'COMPAÑIA ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameA < nameB && a.compania)
                    return -1;
                if (nameA > nameB && a.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'COMPAÑIA ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.compania).toLowerCase()
                const nameB = JSON.stringify(b.compania).toLowerCase()
                if (nameB < nameA && a.compania)
                    return -1;
                if (nameB > nameA && b.compania)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↑') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameA < nameB && a.nombre)
                    return -1;
                if (nameA > nameB && a.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'CLIENTE ↓ZA') {
            updatedList.sort(function (a, b) {
                const nameA = JSON.stringify(a.nombre).toLowerCase()
                const nameB = JSON.stringify(b.nombre).toLowerCase()
                if (nameB < nameA && a.nombre)
                    return -1;
                if (nameB > nameA && b.nombre)
                    return 1;
            })
        }
        if (sortA.sort === 'VTO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaVencimiento) - new Date(a.fechaVencimiento);
            })
        }
        if (sortA.sort === 'VTO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaVencimiento) - new Date(b.fechaVencimiento);
            })
        }
        if (sortA.sort === 'VTO ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.fechaVencimiento, "DD/MM/YYYY").toDate())) - new Date((moment(a.fechaVencimiento, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'VTO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.fechaVencimiento, "DD/MM/YYYY").toDate())) - new Date((moment(b.fechaVencimiento, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'ULT MOD ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.ultimaMod) - new Date(a.ultimaMod);
            })
        }
        if (sortA.sort === 'ULT MOD ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.ultimaMod) - new Date(b.ultimaMod);
            })
        }
        if (sortA.sort === 'ULT MOD ↑') {
            updatedList.sort(function (a, b) {
                return new Date((moment(b.ultimaMod, "DD/MM/YYYY").toDate())) - new Date((moment(a.ultimaMod, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'ULT MOD ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date((moment(a.ultimaMod, "DD/MM/YYYY").toDate())) - new Date((moment(b.ultimaMod, "DD/MM/YYYY").toDate()));
            })
        }
        if (sortA.sort === 'STRO ↑') {
            updatedList.sort(function (a, b) {
                return new Date(b.fechaStro) - new Date(a.fechaStro);
            })
        }
        if (sortA.stro === 'STRO ↓ZA') {
            updatedList.sort(function (a, b) {
                return new Date(a.fechaStro) - new Date(b.fechaStro);
            })
        }
        if (sortA.sort === 0) {
            updatedList.sort((a, b) => b)
        }
    }
    sort()
    function compararPrioridad(a, b) {
        if (a.prioridad === "ALTA" && b.prioridad !== "ALTA") {
            return -1; // a debe ir antes que b
        } else if (a.prioridad !== "ALTA" && b.prioridad === "ALTA") {
            return 1; // b debe ir antes que a
        } else {
            return 0; // no se cambian de posición
        }
    }
    const filteredExpedientes = expedientes.filter((e) => e.asignadoA === cookies.user.nombre && e.novAsig === 0 && e.novCom === 1 && e.estado !== "COBRADO" && e.estado !== "CERRADO");
    const levels = [
        { label: "Nivel 1", value: filteredExpedientes.filter(e => e.nivel === "1").length, color: "nivel1-bg" },
        { label: "Nivel 2", value: filteredExpedientes.filter(e => e.nivel === "2").length, color: "nivel2-bg" },
        { label: "Nivel 3", value: filteredExpedientes.filter(e => e.nivel === "3").length, color: "nivel3-bg" },
    ];
    const total = levels.reduce((acc, level) => acc + level.value, 0);
    const levels2 = [
        { label: "Hoy", value: vencenHoy, color: "vencimiento1-bg" },
        { label: "Mañana", value: vencenManiana, color: "vencimiento2-bg" },
    ];
    const total2 = levels2.reduce((acc, level) => acc + level.value, 0);
    return (
        <main className="mainNovedades2025 pb-5">
            <h2 className="tituloNovedades2025">Buen Día, <strong>{cookies.user.nombre}</strong>!</h2>
            <h3 className="subtituloNovedades2025">Acá tenés las estadísticas del día</h3>
            <section style={{ paddingTop: '10px', display: 'flex', gap: '10px' }}>
                <div style={{ width: '80%' }}>
                    <div className="d-flex" style={{ gap: '16px' }}>
                        <div className={filtroAsignado === true ? "botonFiltroNovedades2025Activo" : "botonFiltroNovedades2025"} onClick={(e) => setFiltroAsignado(!filtroAsignado)}>
                            <div className="d-flex">
                                <h4 className="textoFiltro1Novedades2025">Nuevos<br />Asignados</h4>
                                <h4 className="cantidadFiltro1Novedades2025">{expedientes.filter(e => e.asignadoA === cookies.user.nombre && e.novAsig === 1 && e.estado !== 'COBRADO' && e.estado !== "CERRADO").length}</h4>
                            </div>
                            <h6 className="subtextoFiltro1Novedades2025">Sin Confirmar</h6>
                            <h5 className="ultimoTextoFiltro1Novedades2025">{expedientes.filter(e => e.asignadoA === cookies.user.nombre && e.estado !== 'COBRADO' && e.estado !== "CERRADO").length} / Total</h5>
                        </div>
                        <div className={filtroComentario === true ? "botonFiltroNovedades2025Activo" : "botonFiltroNovedades2025"} onClick={(e) => setFiltroComentario(!filtroComentario)}>
                            <div className="d-flex">
                                <h4 className="textoFiltro1Novedades2025">Nuevos <br />Comentarios</h4>
                                <h4 className="cantidadFiltro1Novedades2025">{expedientes.filter(e => e.asignadoA === cookies.user.nombre && e.novCom === 1 && e.estado !== 'COBRADO' && e.estado !== "CERRADO").length}</h4>
                            </div>
                            <div className="progress progress-container">
                                {levels.map((level, index) =>
                                    level.value > 0 ? (
                                        <div
                                            key={index}
                                            className={`progress-bar ${level.color} progress-segment ${index === levels.length - 1 ? "last-segment" : ""
                                                }`}
                                            role="progressbar"
                                            style={{ width: `${(level.value / total) * 100}%` }}
                                        ></div>
                                    ) : null
                                )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 gap-2">
                                {levels.map((level, index) => (
                                    <div key={index} className="d-flex align-items-center">
                                        <span className={`color-box ${level.color}`}></span>
                                        <strong className="nroProgressBar">{level.value}</strong>
                                        <span className="textoReferenciaProgresoNivel">{level.label}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={filtroFresh === true ? "botonFiltroNovedades2025Activo" : "botonFiltroNovedades2025"} onClick={(e) => setFiltroFresh(!filtroFresh)}>
                            <div className="d-flex">
                                <h4 className="textoFiltro1Novedades2025">Mensajes Freshdesk</h4>
                                <h4 className="cantidadFiltro1Novedades2025"></h4>
                            </div>
                            <h6 className="subtextoFiltro1Novedades2025">En expedientes asignados</h6>
                            <img src={fresh} alt="" style={{ width: '36px', heigth: '36px', marginTop: '4px' }} />
                        </div>
                        <div className="botonFiltroNovedades2025">
                            <div className="d-flex">
                                <h4 className="textoFiltro1Novedades2025">Expedientes<br />por vencer</h4>
                                <h4 className="cantidadFiltro1Novedades2025">{total2}</h4>
                            </div>
                            <div className="progress progress-container">
                                {levels2.map((level, index) =>
                                    level.value > 0 ? (
                                        <div
                                            key={index}
                                            className={`progress-bar ${level.color} progress-segment ${index === levels.length - 1 ? "last-segment" : ""
                                                }`}
                                            role="progressbar"
                                            style={{ width: `${(level.value / total) * 100}%` }}
                                        ></div>
                                    ) : null
                                )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2 gap-2">
                                {levels2.map((level, index) => (
                                    <div key={index} className="d-flex align-items-center">
                                        <span className={`color-box ${level.color}`}></span>
                                        <strong className="nroProgressBar">{level.value}</strong>
                                        <span className="textoReferenciaProgresoNivel">{level.label}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '32px' }}>
                        {preCarga === true ?
                            (updatedList.length > 0 ?
                                <div>
                                    {(filtroNovedades.ticket.pas.length === 0 &&
                                        filtroNovedades.ticket.asignado.length === 0 &&
                                        filtroNovedades.ticket.comp.length === 0 &&
                                        filtroNovedades.ticket.estado.length === 0 &&
                                        filtroNovedades.ticket.fecha.length === 0 &&
                                        !filtroNovedades.ticket.info &&
                                        filtroNovedades.ticket.nivel.length === 0 &&
                                        filtroNovedades.ticket.prioridad.length === 0) ?
                                        updatedList.sort(compararPrioridad).slice(itemOffset, Number(itemOffset + 10)).map((expediente) => {
                                            return <NovedadesTicket2025 key={expediente.id} expediente={expediente} />;
                                        }) : updatedList.slice(itemOffset, Number(itemOffset + 10)).map((expediente) => {
                                            return <NovedadesTicket2025 key={expediente.id} expediente={expediente} />;
                                        })}
                                </div> :
                                <div className="center mt-5"><h5 className="center mt-5">Sin Resultados</h5></div>
                            ) :
                            <div className="center mt-5 pt-5">
                                <div class="spinner-border text-primary center" role="status"></div>
                            </div>}
                        <div className="center mt-5">
                            <Excel data={updatedList} />
                        </div>
                    </div>
                </div>
                <div style={{ width: '20%' }}>
                    <div className="contenedorFiltroNovedades2025">
                        <div className="d-flex gap-3 center" style={{ paddingTop: '20px' }}>
                            <div className="">
                                <button onClick={(e) => filtrar()} className="botonFiltrarNovedades2025">FILTRAR</button>
                            </div>
                            <div className="">
                                <button onClick={(e) => vaciar()} className="botonVaciarNovedades2025">VACIAR</button>
                            </div>
                        </div>
                        <form style={{ paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px' }} onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                            <input placeholder={info.info ? info.info : 'BUSQUEDA GENERAL'} value={info.info} type="text" class="inputsNovedades2025"
                                onChange={(e) =>
                                    setInfo((prevState) => ({
                                        ...prevState,
                                        info: e.target.value,
                                    }))
                                }
                            />
                            <div className="row" style={{ paddingTop: '20px' }}>
                                <div className="col-6">
                                    <MultiSelect
                                        className="customMultiselectNovedades2025"
                                        labelledBy="Asignado"
                                        overrideStrings={{ selectSomeItems: "Asignado" }}
                                        options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                        value={info.asignado}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                asignado: e
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <MultiSelect
                                        className="customMultiselectNovedades2025"
                                        labelledBy="Prioridad"
                                        overrideStrings={{ selectSomeItems: "Prioridad" }}
                                        options={prioridadInput}
                                        value={info.prioridad}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                prioridad: e
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: '20px' }}>
                                <div className="col-6">
                                    <MultiSelect
                                        className="customMultiselectNovedades2025"
                                        labelledBy="Estado"
                                        overrideStrings={{ selectSomeItems: "Estado" }}
                                        options={estadoInput}
                                        value={info.estado}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                estado: e
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <MultiSelect
                                        className="customMultiselectNovedades2025"
                                        labelledBy="Nivel"
                                        overrideStrings={{ selectSomeItems: "Nivel" }}
                                        options={nivelInput}
                                        value={info.nivel}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                nivel: e
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: '20px' }}>
                                <div className="col-6">
                                    <MultiSelect
                                        className="customMultiselectNovedades2025"
                                        labelledBy="Compañia"
                                        overrideStrings={{ selectSomeItems: "Compañia" }}
                                        options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                        value={info.comp}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                comp: e
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <MultiSelect
                                        className="customMultiselectNovedades2025"
                                        labelledBy="Productor"
                                        overrideStrings={{ selectSomeItems: "Productor" }}
                                        options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                        value={info.pas}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                pas: e
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <div className="row">
                                    <div className="col-6">
                                        <input type="number" class="inputsNovedades2025" value={info.expFrom} placeholder={info.expFrom || "Nro Desde"}
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    expFrom: e.target.value,
                                                }))} />
                                    </div>
                                    <div className="col-6">
                                        <input type="number" class="inputsNovedades2025" value={info.expTo} placeholder={info.expTo || "Nro Hasta"}
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    expTo: e.target.value,
                                                }))} />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ paddingTop: '20px' }}>
                                <div className="col-6">
                                    <MultiSelect
                                        className="customMultiselectNovedades2025"
                                        labelledBy="Vencimiento"
                                        overrideStrings={{ selectSomeItems: "Vencimiento" }}
                                        options={fechaInput}
                                        value={info.fecha}
                                        onChange={(e) => {
                                            setInfo((prevState) => ({
                                                ...prevState,
                                                fecha: e
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <Select
                                        placeholder={sortA.sort ? sortA.sort : 'Ordenar Por'}
                                        className="customMultiselectNovedades2025"
                                        options={ordenarInput}
                                        onChange={(newValue) => setSortA((prevState) => ({
                                            sort: newValue.value,
                                        }))}
                                    />
                                </div>
                            </div>
                        </form>
                        <h6 className='resultadosNovedades2025 center' style={{ paddingTop: '20px' }}>Resultados: {updatedList.length}</h6>
                        <ReactPaginate
                            className='center p-0'
                            forcePage={currentPage}
                            activeClassName={'item2 active2'}
                            breakClassName={'item2 break-me'}
                            breakLabel={'...'}
                            marginPagesDisplayed={1}
                            onPageChange={handlePageClick}
                            pageClassName={'item2 pagination-page '}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                        />
                    </div>
                </div>
            </section>
        </main >
    )
}
