import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import Mensaje from "./Mensaje"
import './detalles.css'
import { useCookies } from 'react-cookie';
export default function Detalles({ expediente, mediacion, }) {
    const [mensaje, setMensaje] = useState({ mensaje: '', ofrecimiento: false, privado: false, clavado: false })
    const [filtroMensaje, setFiltroMensaje] = useState({ filtro: false, estado: null })
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [detalles, setDetalles] = useState([])
    const [estados, setEstados] = useState()
    const [queEstado, setQueEstado] = useState(expediente.estado)
    useEffect(() => {
        axios.get(`https://api.tuveunchoque.com.ar/api/gerencia/detalles/${expediente.id}`).then((res) => {
            setDetalles(res.data);
        });
        axios.get(`https://api.tuveunchoque.com.ar/api/estados/all`).then((res) => {
            setEstados(res.data);
        });
    }, [expediente]);
    const today = new Date()
    const now = today.toLocaleString()
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const comentarioUpdate = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
            {
                idExp: expediente.id,
                cargo: cookies.user.cargo,
                mensaje: mensaje.mensaje,
                user: cookies.user.chat,
                estado: expediente.estado,
                clavado: mensaje.clavado,
                hoy: now,
                ofrecimiento: mensaje.ofrecimiento,
                privado: mensaje.privado,
                modificadoPor: cookies.user.nombre,
                esNovedades: expediente.asignadoA !== cookies.user.nombre ? 1 : 0
            }
        ).then((response) => {
            if (response) {
                setTimeout(async () => {
                    await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
                    localStorage.setItem('hora', formatTime(time))
                    window.location.reload()
                }, 1000);
            }
        })
    }, [mensaje, expediente]);
    const conFiltro = () => {
        if (expediente.nro < 100) {
            if (filtroMensaje.filtro && !filtroMensaje.estado) {
                return detalles.filter(detalles => detalles['OFRECIMIENTO'] === true).map(detalle => {
                    return (<Mensaje mensaje={detalle}  key={detalles['ID']} />)
                })
            } else if (!filtroMensaje.filtro && filtroMensaje.estado) {
                return detalles.filter(detalles => detalles['ESTADO'] === filtroMensaje.estado).map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
            } else if (filtroMensaje.filtro && filtroMensaje.estado) {
                return detalles.filter(detalles => detalles['OFRECIMIENTO'] === true && detalles['ESTADO'] === filtroMensaje.estado).map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
            } else {
                return detalles.map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
            }
        } else {
            if (filtroMensaje.filtro && !filtroMensaje.estado) {
                return detalles.filter(detalles => detalles['OFRECIMIENTO'] === true).map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
            } else if (!filtroMensaje.filtro && filtroMensaje.estado) {
                return detalles.filter(detalles => detalles['ESTADO'] === filtroMensaje.estado).map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
            } else if (filtroMensaje.filtro && filtroMensaje.estado) {
                return detalles.filter(detalles => detalles['OFRECIMIENTO'] === true && detalles['ESTADO'] === filtroMensaje.estado).map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
            } else {
                return detalles.map(detalle => {
                    return (<Mensaje mensaje={detalle} key={detalles['ID']} />)
                })
            }
        }
    }
    const estadoInput = [
        "RECEPCIONADO",
        "SIN DENUNCIA",
        "ARMADO PRELIMINAR",
        "RECLAMO PRESENTADO",
        "ACEPTACION",
        "EN PROCESO DE PAGO",
        "COBRADO",
        "MEDIACION",
        "RECHAZADO",
    ];
    return (
        <div className='detallesContainer'>
            <div className="mensajesContenedorConScroll">
                {conFiltro()}
            </div>
            <div className={cookies.user.cargo === 'PAS' ? 'd-none' : 'mt-3'}>
                {expediente.nro < 100 ?
                    <form class="" onSubmit={comentarioUpdate}>
                        <input type="text" className="burbujaMensaje" value={mensaje.mensaje} onChange={(e) => setMensaje((prevState) => ({ ...prevState, mensaje: e.target.value, }))} />
                        <div className="d-flex align-items-center justify-content-between" style={{ marginTop: '10px', marginLeft: '20px', marginRight: '20px' }}>
                            <select class="selectMsjAutomaticos" onChange={(e) => setQueEstado(e.target.value)} value={queEstado}>
                                <option value="" disabled selected>Estado</option>
                                {estados?.map((estado, index) => (
                                    <option key={index} value={estado.estado}>
                                        {estado.estado}
                                    </option>
                                ))}
                            </select>
                            <select class="selectMsjAutomaticos" style={{ maxWidth: '180px', marginLeft: '20px' }} onChange={(e) => setMensaje({ ...mensaje, mensaje: e.target.value })} value={''}>
                                <option value="" disabled selected>MSJs Automaticos</option>
                                {estados?.filter(e => e.estado === queEstado).map((estado, index) => (
                                    estado.msjAutomaticos.split(',').map((msj, i) => (
                                        <option key={`${index}-${i}`} value={msj.trim()}>
                                            {msj.trim()}
                                        </option>
                                    ))
                                ))}
                            </select>
                            {mensaje.privado === false ? <button style={{ marginLeft: '20px' }} type="button" onClick={() => setMensaje({ ...mensaje, privado: true })} className="privadoSinSelect">Privado</button>
                                : <button style={{ marginLeft: '20px' }} className="privadoSelect" type="button" onClick={() => setMensaje({ ...mensaje, privado: false })}>Privado</button>}
                            {mensaje.clavado === false ? <button style={{ marginLeft: '20px' }} type="button" onClick={() => setMensaje({ ...mensaje, clavado: true })} className="clavadoSinSelect">Clavado</button>
                                : <button style={{ marginLeft: '20px' }} type="button" onClick={() => setMensaje({ ...mensaje, clavado: false })} className="clavadoSelect">Clavado</button>}
                            {mensaje.ofrecimiento === false ? <button type="button" style={{ marginLeft: '20px' }} onClick={() => setMensaje({ ...mensaje, ofrecimiento: true })} className="ofrecimientoSinSelect">Ofrecimiento</button>
                                : <button style={{ marginLeft: '20px' }} type="button" onClick={() => setMensaje({ ...mensaje, ofrecimiento: false })} className="ofrecimientoSelect">Ofrecimiento</button>}
                            <button style={{ marginLeft: '20px' }} type="submit" className="btnEnviarSubmit">Enviar</button>
                        </div>
                    </form>
                    :
                    <form class="" onSubmit={comentarioUpdate}>
                        <div className="row ms-3">
                            <div className="col rounded-pill ofrecimientoBtn d-flex">
                                <input type="checkbox" value={mensaje.ofrecimiento} className="form-check-input" id="ofrecimiento"
                                    onChange={(e) =>
                                        setMensaje((prevState) => ({
                                            ...prevState,
                                            ofrecimiento: e.target.checked,
                                        }))} />
                                <label class="form-check-label ofreLabel" for="ofrecimiento">Ofrecimiento</label>
                            </div>
                            <div className="col me-1 rounded-pill text-white privadoBtn d-flex">
                                <input type="checkbox" value={mensaje.privado} className="form-check-input me-1" id="privado"
                                    onChange={(e) =>
                                        setMensaje((prevState) => ({
                                            ...prevState,
                                            privado: e.target.checked,
                                        }))} />
                                <label class="form-check-label privLabel" for="privado">Privado</label>
                            </div>
                            <div className="col me-1 rounded-pill text-white clavadoBtn d-flex">
                                <input type="checkbox" value={mensaje.privado} className="form-check-input me-1" id="clavado"
                                    onChange={(e) =>
                                        setMensaje((prevState) => ({
                                            ...prevState,
                                            clavado: e.target.checked,
                                        }))} />
                                <label class="form-check-label claLabel" for="clavado">Clavado</label>
                            </div>
                            <div className="col me-1 ofrecimientoBtn d-flex">
                                <input type="checkbox" value={filtroMensaje.filtro} className="form-check-input me-1" id="ofrecimiento"
                                    onChange={(e) =>
                                        setFiltroMensaje((prevState) => ({
                                            ...prevState,
                                            filtro: e.target.checked,
                                        }))} />
                                <label class="form-check-label ofreLabel" for="ofrecimiento">Filt. Ofrec</label>
                            </div>
                            <div className="col me-1">
                                <select
                                    className="w-100"
                                    onChange={(e) =>
                                        setFiltroMensaje((prevState) => ({
                                            ...prevState,
                                            estado: e.target.value,
                                        }))
                                    }
                                >
                                    <option value="" selected>
                                        Estado{" "}
                                    </option>
                                    {estadoInput.map((estado) => (
                                        <option value={estado}>{estado}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="center ms-3 mt-2">
                            <textarea className="form-control form-control-sm" style={{ height: '50px', resize: 'none' }} value={mensaje.mensaje}
                                onChange={(e) =>
                                    setMensaje((prevState) => ({
                                        ...prevState,
                                        mensaje: e.target.value,
                                    }))} />
                            <button class="btn btn-sm shadow-none" type="submit">Enviar</button>
                        </div>
                    </form>}
            </div>
        </div>
    )
}


/*<div className="">
                        <div className="col rounded-pill ofrecimientoBtn d-flex">
                            <input type="checkbox" value={mensaje.ofrecimiento} className="form-check-input" id="ofrecimiento"
                                onChange={(e) =>
                                    setMensaje((prevState) => ({
                                        ...prevState,
                                        ofrecimiento: e.target.checked,
                                    }))} />
                            <label class="form-check-label ofreLabel" for="ofrecimiento">Ofrecimiento</label>
                        </div>
                        <div className="col me-1 rounded-pill text-white privadoBtn d-flex">
                            <input type="checkbox" value={mensaje.privado} className="form-check-input me-1" id="privado"
                                onChange={(e) =>
                                    setMensaje((prevState) => ({
                                        ...prevState,
                                        privado: e.target.checked,
                                    }))} />
                            <label class="form-check-label privLabel" for="privado">Privado</label>
                        </div>
                        <div className="col me-1 rounded-pill text-white clavadoBtn d-flex">
                            <input type="checkbox" value={mensaje.privado} className="form-check-input me-1" id="clavado"
                                onChange={(e) =>
                                    setMensaje((prevState) => ({
                                        ...prevState,
                                        clavado: e.target.checked,
                                    }))} />
                            <label class="form-check-label claLabel" for="clavado">Clavado</label>
                        </div>
                        <div className="col me-1 ofrecimientoBtn d-flex">
                            <input type="checkbox" value={filtroMensaje.filtro} className="form-check-input me-1" id="ofrecimiento"
                                onChange={(e) =>
                                    setFiltroMensaje((prevState) => ({
                                        ...prevState,
                                        filtro: e.target.checked,
                                    }))} />
                            <label class="form-check-label ofreLabel" for="ofrecimiento">Filt. Ofrec</label>
                        </div>
                        <div className="col me-1">
                            <select
                                className="w-100"
                                onChange={(e) =>
                                    setFiltroMensaje((prevState) => ({
                                        ...prevState,
                                        estado: e.target.value,
                                    }))
                                }
                            >
                                <option value="" selected>
                                    Estado{" "}
                                </option>
                                {estadoInput.map((estado) => (
                                    <option value={estado}>{estado}</option>
                                ))}
                            </select>
                        </div>
                    </div>*/