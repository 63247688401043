import './ticket2025.css'
export default function EstadoNovedades2025({estado}) {
    const estadoClases = {
        'RECEPCIONADO': 'estadoA2025',
        'SIN DENUNCIA': 'estadoB2025',
        'ARMADO PRELIMINAR': 'estadoC2025',
        'RECLAMO PRESENTADO': 'estadoD2025',
        'ACEPTACION': 'estadoE2025',
        'EN PROCESO DE PAGO': 'estadoF2025',
        'COBRADO': 'estadoG2025',
        'MEDIACION': 'estadoH2025',
        'CERRADO': 'estadoI2025',
    };
    const claseEspecifica = estadoClases[estado] || 'estadoDefault2025';
    const claseGeneral = 'estado2025'
    const clasesCompletas = `${claseGeneral} ${claseEspecifica}`;
    return (
        <div className={clasesCompletas}>{estado}</div>
    )
}