import { useCallback, useEffect, useState } from 'react';
import './ticket2025.css'
import fresh from '../../images/fresh.png'
import callbell from "../../images/callbell.jpeg";
import callbellReq from "../../images/calbellReq.jpeg";
import sharepoint from "../../images/sharepoint.jpeg";
import sharepointRojo from "../../images/shareRojo.png";
import NivelNovedades2025 from './NivelNovedades2025';
import EstadoNovedades2025 from './EstadoNovedades2025';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
export default function NovedadesTicket2025({ expediente }) {
    const [cookies, setCookie, removeCookie] = useCookies(["exp"], ['expNovedades']);
    const logo = { height: "29px", width: "29px", background: "transparent 0% 0% no-repeat padding-box" };
    const btn = { border: "none", background: "none" };
    const pasCargo = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(`https://api.tuveunchoque.com.ar/api/mail/ampliacionCargaPas`, {
            id: expediente.id,
            carga: 0
        }).then((response) => {
            if (response) {
                setTimeout(async () => {
                    window.location.reload()
                }, 200);
            }
        })
    }, [expediente])
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const registro = async (e) => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/quitarAsignadoNovedades2025`, { id: expediente.id, bolean: 0, })
        setCookie("expActual", expediente.nro);
        localStorage.setItem('hora', formatTime(time))
    }
    return (
        <div className={isOpen ? "novedadesTicketContainer2025Abierto" : "novedadesTicketContainer2025"}>
            <div className="w-100 d-flex">
                <h2 className='tituloDelTicket2025'>
                    <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} className={expediente.prioridad === 'ALTA' ? "exp-nro tituloDelTicket2025 text-danger" : "exp-nro tituloDelTicket2025"}>{`Exp ${expediente.nro}`}</Link>
                    {expediente.nombre} - {expediente.compania}
                </h2>
                <div className='d-flex gap-2'>
                    {expediente.novFresh === 1 ?
                        <img src={fresh} alt="" style={{ height: '29px', width: '29px', display: 'block' }} />
                        : <div style={{ height: '29px', width: '29px', display: 'block' }} />}
                    {expediente.novCom === 1 ?
                        <div style={{ position: 'relative', width: '29px', height: '29px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className="bi bi-chat-fill" style={{ color: '#FD9124', fontSize: '29px' }}></i>
                            <span style={{ position: 'absolute', color: 'white', fontSize: '16px', fontWeight: 'bold' }}>+</span>
                        </div>
                        : <div style={{ position: 'relative', width: '29px', height: '29px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>}
                    {expediente.novAsig === 1 ?
                        <div style={{ width: '29px', height: '29px', backgroundColor: '#06519B', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className="bi bi-file-earmark-text" style={{ color: 'white', fontSize: '16px' }}></i>
                        </div>
                        : <div style={{ width: '29px', height: '29px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>}
                </div>
                <div className='vtoContainer'>
                    <h5 className='vtoTexto'>VTO<strong style={{ marginLeft: '10px' }}>{expediente.fechaVencimiento}</strong></h5>
                </div>
                <div className='ultModContainer'>
                    <h5 className='ultModTexto'>Ult Mod<strong style={{ marginLeft: '10px' }}>{expediente.ultimaMod}</strong></h5>
                </div>
                <EstadoNovedades2025 estado={expediente.estado} />
                <div className="d-flex gap-2">
                    <NivelNovedades2025 nivel={expediente.nivel} />
                    {expediente.ticket && (
                        <Link to={`https://estudiopyp.freshdesk.com/a/tickets/${expediente.ticket}`} target="_blank" rel="noopener noreferrer">
                            <img src={fresh} alt="" style={logo} />
                        </Link>
                    )}
                    {expediente.callbell && (
                        <Link to={`https://dash.callbell.eu/chat/${expediente.callbell}?filter=all`} target="_blank" rel="noopener noreferrer">
                            <img src={callbell} alt="" style={logo} />
                        </Link>
                    )}
                    {expediente.callbellRequerido && (
                        <Link to={`https://dash.callbell.eu/chat/${expediente.callbellRequerido}?filter=all`} target="_blank" rel="noopener noreferrer">
                            <img src={callbellReq} alt="" style={logo} />
                        </Link>
                    )}
                    {expediente.Sharepoint && (
                        <Link to={`${expediente.sharepoint}`} target="_blank" rel="noopener noreferrer">
                            <img src={sharepoint} alt="" style={logo} />
                        </Link>
                    )}
                    {expediente.pasCargoDocumentacion === 1 && (
                        <button onClick={pasCargo} style={btn}>
                            <img src={sharepointRojo} alt="" style={logo} />
                        </button>
                    )}
                    {expediente.estadoAtras === 1 && (
                        <div style={{ position: 'relative', width: '29px', height: '29px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <i className="bi bi-exclamation-circle-fill text-warning" style={{ fontSize: '29px' }}></i>
                        </div>
                    )}
                    {expediente.anclado && (
                        <div style={{ position: 'relative', width: '29px', height: '29px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='tooltip-container'>
                            <i className="bi bi-chat-dots-fill" style={{ fontSize: '29px', color: '#FD9124' }}></i>
                            <span className="tooltip-text">{`${expediente.ancladoUser}: ${expediente.anclado}`}</span>
                        </div>
                    )}
                </div>
                <div onClick={toggleDropdown} style={{ width: '29px', height: '29px', display: 'flex', alignItems: 'center', justifyContent: 'end', cursor: 'pointer', marginLeft: 'auto' }} className='tooltip-container'>
                    {isOpen ? <i className="bi bi-caret-down-fill" style={{ fontSize: '26px', color: '#70707080' }}></i> : <i className="bi bi-caret-left-fill" style={{ fontSize: '26px', color: '#70707080' }}></i>}
                </div>
            </div>
            {isOpen && (
                <div className="dropdown-content">
                    {expediente.novAsig === 1 ?
                        <div className='w-100 d-flex' style={{ marginTop: '20px' }}>
                            <div style={{ width: '29px', height: '29px', backgroundColor: '#06519B', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '20px' }}>
                                <i className="bi bi-file-earmark-text" style={{ color: 'white', fontSize: '16px' }}></i>
                            </div>
                            <h2 className='segundoBloqueTexto'>Asignado Por: </h2>
                            <h2 className='quienEnvio'>{expediente.asignadoPor}</h2>
                            <h2 className='fechaQuienEnvio'></h2>
                        </div>
                        : <></>}
                    {expediente.novCom === 1 ?
                        <div className='w-100 d-flex' style={{ marginTop: '20px' }}>
                            <div style={{ position: 'relative', width: '29px', height: '29px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '20px' }}>
                                <i className="bi bi-chat-fill" style={{ color: '#FD9124', fontSize: '29px' }}></i>
                                <span style={{ position: 'absolute', color: 'white', fontSize: '16px', fontWeight: 'bold' }}>+</span>
                            </div>
                            <h2 className='segundoBloqueTexto'>Nuevo comentario de:</h2>
                            <h2 className='quienEnvio'>{expediente.asignadoPor}</h2>
                            <h2 className='fechaQuienEnvio'></h2>
                            <h2 className='asuntoQuienEnvio'></h2>
                        </div>
                        : <></>}
                    {expediente.novFresh === 1 ?
                        <div className='w-100 d-flex' style={{ marginTop: '20px' }}>
                            <img src={fresh} alt="" style={{ height: '29px', width: '29px', display: 'block', marginRight: '20px' }} />
                            <h2 className='segundoBloqueTexto'>Mensaje Recibido de: </h2>
                            <h2 className='quienEnvio'>MERCANTIL ANDINA</h2>
                            <h2 className='fechaQuienEnvio'>Fecha: 12/02/2025</h2>
                            <h2 className='asuntoQuienEnvio'>Asunto: Prueba Muestra Freshdesk</h2>
                        </div>
                        : <></>}
                </div>
            )}
        </div>
    );
}   
