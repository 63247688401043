import { useCallback, useContext, useEffect, useState } from "react";
import { editarContext } from "../../../context/Editar";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useMediaQuery } from 'react-responsive'
export default function SeccionCobroLeer({ expediente }) {
    const divLeer = { height: '35px', width: '100%', backgroundColor: 'white' }
    const bot = { background: '#F99F41 0% 0% no-repeat padding-box', borderRadius: '22px', height: '41px', width: '140px', }
    const botonNo = { border: 'none', background: 'none' }
    const { setEditar } = useContext(editarContext)
    const [cookies] = useCookies(['user']);
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const editarFunction = async () => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        setEditar(true)
    }
    const editarBtn = { background: '#0731FF 0% 0% no-repeat padding-box', borderRadius: '22px', width: '135px', height: '41px' }
    const avisoPago = useCallback(async (e) => {
        e.preventDefault()
        await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/expedientes/update/avisoPagoCobroPas`, { id: expediente.id,  }).then(async (response) => {
            if (response) {
                axios.post(`https://api.tuveunchoque.com.ar/api/mail/avisoPagoCobro`, { nro: expediente.nro, pas: expediente.productorAsociado })
                window.location.reload()
            }
        })
    }, [expediente])




    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 834px)' });
    return (
        <>
            {isMobile375 ?
                <>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Ofrecimiento</label>
                            <div type="text" class="recuasroTexto375">{expediente.capital}</div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Mes Cobrado</label>
                            <div type="text" class="recuasroTexto375">{expediente.mesCobrado?.split("-").reverse().join("-")}</div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Mes Estimado</label>
                            <div type="text" class="recuasroTexto375">{expediente.mesEstimado?.split("-").reverse().join("-")}</div>
                        </div>
                        <div className="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Modo de Pago</label>
                            <div type="text" class="recuasroTexto375">{expediente.modoDePago}</div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Honorarios Facturado</label>
                            <div type="text" class="recuasroTexto375">{expediente.honorariosFacturado}</div>
                        </div>
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Honorarios Cliente</label>
                            <div type="text" class="recuasroTexto375">{expediente.hnriosCliente}</div>
                        </div>
                        <div className="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Hnrios Cobrados Cia</label>
                            <div type="text" class="recuasroTexto375">{expediente.honorariosCobro}</div>
                        </div>
                    </div>
                    <div class="row m-0 ms-1 me-1">
                        <div class="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Estado Cobro</label>
                            <div type="text" class="recuasroTexto375">{expediente.estadoCobro}</div>
                        </div>
                        <div className="col p-0 me-1">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Nro Factura</label>
                            <div type="text" class="recuasroTexto375">{expediente.nroFactura}</div>
                        </div>
                        <div class="col p-0">
                            <label class="mb-1 letraPlanillaExpedienteTexto375">Facturado Por</label>
                            <div type="text" class="recuasroTexto375">{expediente.facturadoPor}</div>
                        </div>
                    </div>
                    <div className="mb-3"></div>
                </>
                :
                (isMobile412 ?
                    <>
                        <div class="row m-0 ms-1 me-1">
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Ofrecimiento</label>
                                <div type="text" class="recuasroTexto375">{expediente.capital}</div>
                            </div>
                            <div class="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Mes Cobrado</label>
                                <div type="text" class="recuasroTexto375">{expediente.mesCobrado?.split("-").reverse().join("-")}</div>
                            </div>
                        </div>
                        <div class="row m-0 ms-1 me-1">
                            <div class="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Mes Estimado</label>
                                <div type="text" class="recuasroTexto375">{expediente.mesEstimado?.split("-").reverse().join("-")}</div>
                            </div>
                            <div className="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Modo de Pago</label>
                                <div type="text" class="recuasroTexto375">{expediente.modoDePago}</div>
                            </div>
                        </div>
                        <div class="row m-0 ms-1 me-1">
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Honorarios Facturado</label>
                                <div type="text" class="recuasroTexto375">{expediente.honorariosFacturado}</div>
                            </div>
                            <div class="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Honorarios Cliente</label>
                                <div type="text" class="recuasroTexto375">{expediente.hnriosCliente}</div>
                            </div>
                            <div className="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Hnrios Cobrados Cia</label>
                                <div type="text" class="recuasroTexto375">{expediente.honorariosCobro}</div>
                            </div>
                        </div>
                        <div class="row m-0 ms-1 me-1">
                            <div class="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Estado Cobro</label>
                                <div type="text" class="recuasroTexto375">{expediente.estadoCobro}</div>
                            </div>
                            <div className="col p-0 me-1">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Nro Factura</label>
                                <div type="text" class="recuasroTexto375">{expediente.nroFactura}</div>
                            </div>
                            <div class="col p-0">
                                <label class="mb-1 letraPlanillaExpedienteTexto375">Facturado Por</label>
                                <div type="text" class="recuasroTexto375">{expediente.facturadoPor}</div>
                            </div>
                        </div>
                        <div className="mb-3"></div>
                    </>
                    :
                    (isMobile480 ?
                        <>
                            <div class="row m-0 ms-1 me-1">
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Ofrecimiento</label>
                                    <div type="text" class="recuasroTexto375">{expediente.capital}</div>
                                </div>
                                <div class="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Mes Cobrado</label>
                                    <div type="text" class="recuasroTexto375">{expediente.mesCobrado?.split("-").reverse().join("-")}</div>
                                </div>
                            </div>
                            <div class="row m-0 ms-1 me-1">
                                <div class="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Mes Estimado</label>
                                    <div type="text" class="recuasroTexto375">{expediente.mesEstimado?.split("-").reverse().join("-")}</div>
                                </div>
                                <div className="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Modo de Pago</label>
                                    <div type="text" class="recuasroTexto375">{expediente.modoDePago}</div>
                                </div>
                            </div>
                            <div class="row m-0 ms-1 me-1">
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Honorarios Facturado</label>
                                    <div type="text" class="recuasroTexto375">{expediente.honorariosFacturado}</div>
                                </div>
                                <div class="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Honorarios Cliente</label>
                                    <div type="text" class="recuasroTexto375">{expediente.hnriosCliente}</div>
                                </div>
                                <div className="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Honorarios Cobrados Cia</label>
                                    <div type="text" class="recuasroTexto375">{expediente.honorariosCobro}</div>
                                </div>
                            </div>
                            <div class="row m-0 ms-1 me-1">
                                <div class="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Estado Cobro</label>
                                    <div type="text" class="recuasroTexto375">{expediente.estadoCobro}</div>
                                </div>
                                <div className="col p-0 me-1">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Nro Factura</label>
                                    <div type="text" class="recuasroTexto375">{expediente.nroFactura}</div>
                                </div>
                                <div class="col p-0">
                                    <label class="mb-1 letraPlanillaExpedienteTexto375">Facturado Por</label>
                                    <div type="text" class="recuasroTexto375">{expediente.facturadoPor}</div>
                                </div>
                            </div>
                            <div className="mb-3"></div>
                        </>
                        :
                        (isTablet768 ?
                            <>
                                <div class="row m-0 ms-1 me-1">
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Ofrecimiento</label>
                                        <div type="text" class="recuasroTexto768">{expediente.capital}</div>
                                    </div>
                                    <div class="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Mes Cobrado</label>
                                        <div type="text" class="recuasroTexto768">{expediente.mesCobrado?.split("-").reverse().join("-")}</div>
                                    </div>
                                </div>
                                <div class="row m-0 ms-1 me-1 mt-2">
                                    <div class="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Mes Estimado</label>
                                        <div type="text" class="recuasroTexto768">{expediente.mesEstimado?.split("-").reverse().join("-")}</div>
                                    </div>
                                    <div className="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Modo de Pago</label>
                                        <div type="text" class="recuasroTexto768">{expediente.modoDePago}</div>
                                    </div>
                                </div>
                                <div class="row m-0 ms-1 me-1 mt-2">
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Honorarios Facturado</label>
                                        <div type="text" class="recuasroTexto768">{expediente.honorariosFacturado}</div>
                                    </div>
                                    <div class="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Honorarios Cliente</label>
                                        <div type="text" class="recuasroTexto768">{expediente.hnriosCliente}</div>
                                    </div>
                                    <div className="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Honorarios Cobrados Cia</label>
                                        <div type="text" class="recuasroTexto375">{expediente.honorariosCobro}</div>
                                    </div>
                                </div>
                                <div class="row m-0 ms-1 me-1 mt-2">
                                    <div class="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Estado Cobro</label>
                                        <div type="text" class="recuasroTexto768">{expediente.estadoCobro}</div>
                                    </div>
                                    <div className="col p-0 me-1">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Nro Factura</label>
                                        <div type="text" class="recuasroTexto768">{expediente.nroFactura}</div>
                                    </div>
                                    <div class="col p-0">
                                        <label class="mb-1 letraPlanillaExpedienteTexto768">Facturado Por</label>
                                        <div type="text" class="recuasroTexto768">{expediente.facturadoPor}</div>
                                    </div>
                                </div>
                                <div className="mb-3"></div>
                            </> :
                            <div>
                                <div className="mt-1 letra pb-5 pe-3 ps-3">
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Ofrecimiento</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.capital}</div>
                                        </div>
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Mes Cobrado</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.mesCobrado?.split("-").reverse().join("-")}</div>
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Mes Estimado</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.mesEstimado?.split("-").reverse().join("-")}</div>
                                        </div>
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Modo de Pago</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.modoDePago}</div>
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Honorarios Facturado</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.honorariosFacturado}</div>
                                        </div>
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Honorarios Cliente</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.hnriosCliente}</div>
                                        </div>
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Honorarios Cobrados Cia</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.honorariosCobro}</div>
                                        </div>
                                    </div>
                                    <div class="mb-2 row">
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Estado Cobro</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.estadoCobro}</div>
                                        </div>
                                        <div className="col">
                                            <label class="mb-1 letraPlanillaExpediente">Nro Factura</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.nroFactura}</div>
                                        </div>
                                        <div class="col">
                                            <label class="mb-1 letraPlanillaExpediente">Facturado Por</label>
                                            <div type="text" class="form-control" style={divLeer}>{expediente.facturadoPor}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 pe-3 ps-3">
                                    <div className="col">
                                        <div className=" center" style={bot}>
                                            <button style={botonNo} onClick={(e) => avisoPago(e)} className="text-white">Aviso Pago</button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="">
                                            <button className="btn btn-primary btn-sm" onClick={async (e) => editarFunction()} style={editarBtn}>EDITAR</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )))

            }
        </>
    )
}