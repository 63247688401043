import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css";
export default function NivelNovedades2025({ nivel }) {
    let color = 'transparent'
    if (Number(nivel) === 1) {
        color = '#4CAF50'
    } else if (Number(nivel) === 2) {
        color = '#FFC107'
    } else if (Number(nivel) === 3) {
        color = '#F44336'
    }
    return (
        <div style={{height: '28px',width: '28px',backgroundColor: color, borderRadius: '50%', boxShadow: '0 1px 1px rgba(0, 0, 0, 0.2)', marginLeft: '20px'}}></div>
    )
}